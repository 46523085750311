<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">用戶管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.Status = 0; row.Online = false; callback(); }"
          @edit="(row, callback) => { row.Password = undefined; callback(); }"
          @reset="(row, callback) => { callback(); row.Online = false; row.Password = undefined; }"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-role-ids="{ data }">
                <select-box v-bind="selectorOptions" v-model="data.RoleIds" />
              </template>
              <template #column-photo-and-remark="{ data }">
                <div class="flex">
                  <file-uploader
                    :ref="el => uploader = el"
                    id="user-photo-uri"
                    style="min-width: 150px; max-width: 150px; min-height: 150px; max-height: 180px;"
                    class="mr-10"
                    mode="image"
                    :modelValue="data.Photo?.Uri"
                    :action="uploadAction"
                    :autoUpload="true"
                    :limitedWidth="150"
                    :limitedHeight="150"
                    @update:modelValue="(value) => { if (data.Photo) data.Photo.Uri = value; else data.Photo = { Uri: value }; }"
                    @filter="uploaderFilter"
                  />
                  <vxe-textarea placeholder="請輸入備註" resize="none" v-model="data.Remark" />
                </div>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: "user",
      title: "用戶",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "用戶清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "用戶清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, filters: [{ checked: false, label: "包含" }], slots: { filter: "filter-name" } },
        { field: "Account", title: "帳號", showHeaderOverflow: true, showOverflow: true },
        { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true },
        { field: "Status", title: "狀態", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.UserStatus).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "Online", title: "是否在線", showHeaderOverflow: true, showOverflow: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '上線', closeLabel: '離線', disabled: true } } },
      ],
      promises: {
        query: model ? (params) => model.dispatch("user/query", params) : undefined,
        queryAll: model ? () => model.dispatch("user/query") : undefined,
        save: model ? (params) => model.dispatch("user/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "名稱" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.UserStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Account", title: "帳號", span: 12, itemRender: { name: "$input", props: { placeholder: "帳號" } } },
        { field: "Password", title: "密碼", span: 12, itemRender: { name: "$input", props: { type: "password", placeholder: "密碼", autocomplete: "new-password" } } },
        { field: "Email", title: "Email", span: 24, itemRender: { name: "$input", props: { placeholder: "Email" } } },
        { field: "RoleIds", title: "角色", span: 24, slots: { default: "column-role-ids" } },
        { field: "Photo.Uri", title: "頭像", span: 24, slots: { default: "column-photo-and-remark" } },
        { field: "Online", title: "是否在線", span: 12, itemRender: { name: "$switch", props: { openLabel: '上線', closeLabel: '離線', disabled: true } } },
        { field: "LoginIp", title: "IP位址", span: 12, itemRender: { name: "$input", props: { placeholder: "IP位址", disabled: true } } },
        { field: "LoginTime", title: "登入時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "登入時間", disabled: true } } },
        { field: "LogoutTime", title: "登出時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "登出時間", disabled: true } } },
      ],
      rules: {
        Name: [{ required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        Account: [{ required: true }],
        Password: [{
          required: false,
          validator: (params) => {
            if (params.itemValue) {
              const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)");
              if (!regex.test(params.itemValue)) return new Error("須8碼以上含大小寫英文、數字");
            } else if (!params.data.Id) return new Error("新用戶須設定密碼");
          },
        }],
        RoleIds: [{ required: true, validator: (params) => { if (!params.itemValue?.length) return new Error("須選擇至少一個以上的角色"); } }],
      }
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇角色",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("role/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("role/query", params), // eslint-disable-line
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
</script>
